// Import the Bootstrap bundle
//
//
// Import just what we need
//
// import '../../../../../node_modules/bootstrap/js/dist/alert';
// import '../../../../../node_modules/bootstrap/js/dist/button';
// import '../../../../../node_modules/bootstrap/js/dist/carousel';
// import '../../../../../node_modules/bootstrap/js/dist/collapse';
import '../../../../../node_modules/bootstrap/js/dist/dropdown';
import '../../../../../node_modules/bootstrap/js/dist/modal';
// import '../../../../../node_modules/bootstrap/js/dist/popover';
// import '../../../../../node_modules/bootstrap/js/dist/scrollspy';
// import '../../../../../node_modules/bootstrap/js/dist/tab';
// import '../../../../../node_modules/bootstrap/js/dist/toast';
// import '../../../../../node_modules/bootstrap/js/dist/tooltip';

//
// Place any custom JS here
//

// Create an example popover
// document.querySelectorAll('[data-bs-toggle="popover"]')
//   .forEach(popover => {
//     new bootstrap.Popover(popover)
//   })
  
import { Helpers } from "./Helpers";
const helpers = new Helpers();
helpers.initClickHeaderIcon();
